<template>
  <section class="site-social">
    <div class="site-social__wrapper">
      <h2 class="site-social__wrapper__title site-section-title">
        Ce que disent nos clients
      </h2>
      <div class="site-social__wrapper__slider">
        <button
          v-if="feedbacks.length > numberToShow"
          class="site-social__wrapper__slider__btn site-social__wrapper__slider__btn--left"
          :class="{
            'site-social__wrapper__slider__btn--disabled': currentSlide && (currentSlide.value === carousel.data.minSlide.value)
          }"
          @click="slideLeft"
        >
          <img
            class="site-social__wrapper__slider__btn__img"
            alt="Faire définler à droite"
            src="./arrow-left.svg"
          />
        </button>
        <button
          v-if="feedbacks.length > numberToShow"
          class="site-social__wrapper__slider__btn site-social__wrapper__slider__btn--right"
          :class="{
            'site-social__wrapper__slider__btn--disabled': currentSlide && (currentSlide.value === carousel.data.maxSlide.value)
          }"
          @click="slideRight"
        >
          <img
            class="site-social__wrapper__slider__btn__img"
            alt="Faire définler à droite"
            src="./arrow-right.svg"
          />
        </button>
        <Carousel
          :items-to-show="numberToShow"
          :mouse-drag="false"
          :touch-drag="true"
          snap-align="start"
          ref="carousel"
          class="site-social__wrapper__slider__cards"
        >
          <Slide
            v-for="feedback in feedbacks"
            :key="feedback.key"
            class="site-social__wrapper__slider__cards__card"
          >
            <div class="site-social__wrapper__slider__cards__card__inner">
              <div class="site-social__wrapper__slider__cards__card__inner__picture">
                <img
                  v-if="showImages"
                  :src="getAssetSrc(feedback.picture)"
                  :alt="`Photo de ${feedback.name}`"
                >
              </div>
              <div class="site-social__wrapper__slider__cards__card__inner__name">
                {{ feedback.name }}
              </div>
              <div class="site-social__wrapper__slider__cards__card__inner__title">
                {{ feedback.title }}
              </div>
              <div class="site-social__wrapper__slider__cards__card__inner__stars">
                <img
                  v-for="i in [0, 1, 2, 3, 4]"
                  class="site-social__wrapper__slider__cards__card__inner__stars__star"
                  src="./star.svg"
                  alt="Image d'étoile"
                >
              </div>
              <div class="site-social__wrapper__slider__cards__card__inner__quote">
                <img src="./quote.svg" alt="Image de guillements">
              </div>
              <div class="site-social__wrapper__slider__cards__card__inner__comment">
                {{ feedback.comment }}
              </div>
            </div>
          </Slide>
        </Carousel>
        <div
          class="site-social__wrapper__slider__puces"
          v-if="feedbacks.length > numberToShow"
        >
          <SiteSocialPuce
            :key="index"
            v-for="(f, index) in rangeItemsToShow"
            :active="currentSlide && currentSlide.value === index"
            class="site-social__wrapper__slider__puces__puce"
          />
        </div>
      </div>
    </div>
    <GlobalEvents
      target="window"
      @resize="majNumberToShow"
    />
  </section>
</template>

<script setup>
  import 'vue3-carousel/dist/carousel.css'
  import SiteSocialPuce from './Puce'
  import { Carousel, Slide } from 'vue3-carousel'
  import { computed, onMounted } from 'vue'
  import { GlobalEvents } from 'vue-global-events'
  import range from 'lodash.range'

  const carousel = ref(null)

  const showImages = ref(false)

  setTimeout(() => {
    showImages.value = true
  }, 1000)

  const currentSlide = computed(() => {
    return carousel.value && carousel.value.data.currentSlide
  })

  const getAssetSrc = (name) => {
    const path = `./pictures/${name}.webp`;
    const modules = import.meta.glob("./pictures/*", { eager: true });
    const mod = modules[path];
    return mod.default;
  };

  const feedbacks = [{
    key: 1,
    name: 'Luc M.',
    title: 'Développeur Back End et Ops',
    picture: 'luc',
    comment: 'Freelance Régie Tools est un outil vraiment pratique qui fait gagner beaucoup de temps, que ce soit pour moi ou pour le client qui n’a pas besoin d’imprimer/signer/scanner le cra. Et la génération de facture avec envoi est top!'
  },
  {
    key: 2,
    name: 'Jonathan M.',
    title: 'Développeur Front End',
    picture: 'jonathan',
    comment: 'Freelance Régie Tools, c\'est une gestion simple et efficace des contrats et factures de ma société au quotidien!'
  }, {
    key: 3,
    name: 'Jonathan R.',
    title: 'Développeur Javascript',
    picture: 'jonathan_2',
    comment: 'Grâce à Freelance Régie Tools, finit les scans/impressions de CRA à chaque fin de mois et les avoirs pour corriger les erreurs de copier/coller sur les factures!'
  }]

  const numberToShow = ref(1)

  const majNumberToShow = () => {
    if (window.innerWidth > 1100) {
      numberToShow.value = 3
    } else if (window.innerWidth > 750) {
      numberToShow.value = 2
    } else {
      numberToShow.value = 1
    }
  }

  onMounted(majNumberToShow)

  const slideLeft = () => {
    carousel.value.prev()
  }

  const slideRight = () => {
    carousel.value.next()
  }

  const rangeItemsToShow = computed(() => {
    if (carousel.value && carousel.value.data.maxSlide.value) {
      return range(carousel.value.data.maxSlide.value + 1)
    }
    return []
  })
</script>

<style lang="scss">

  $desktop-breakpoint: 990px;
  $tablet-breakpoint: 672px;

  .site-social {
    display: flex;
    justify-content: center;
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 15px;
      
      @media screen and (min-width: $tablet-breakpoint) {
        padding-bottom: 12px;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        margin-bottom: 25px;
      }

      &__title {
        color: #01498B;
        margin-bottom: 0px;
        @media screen and (min-width: $tablet-breakpoint) {
          padding-bottom: 12px;
        }

        @media screen and (min-width: $desktop-breakpoint) {
          margin-bottom: 25px;
        }
      }

      &__slider {
        padding: 0 37px;
        position: relative;
        @media screen and (min-width: $desktop-breakpoint) {
          padding: 0;
        }

        &__btn {
          height: 26px;
          width: 26px;
          border-radius: 13px;
          background-color: #01498B;
          position: absolute;
          top: 50%;
          border: 0;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          &__img {
            width: 8px;
            height: 12px;
          }

          @media screen and (min-width: $tablet-breakpoint) {
            height: 39px;
            width: 39px;
            border-radius: 19.5px;

            &__img {
              width: 11.5px;
              height: 17.25px;
            }
          }
          @media screen and (min-width: $desktop-breakpoint) {
            border-radius: 26px;
            height: 52px;
            width: 52px;

            &__img {
              width: 14px;
            }
          }
          &--left {
            left: 7px;

            @media screen and (min-width: $tablet-breakpoint) {
              left: -15px
            }
            @media screen and (min-width: $desktop-breakpoint) {
              left: -60px
            }

            img {
              transform: translateX(-10%);
            }
          }
          &--right {
            right: 7px;

            @media screen and (min-width: $tablet-breakpoint) {
              right: -15px
            }
            @media screen and (min-width: $desktop-breakpoint) {
              right: -60px
            }

            img {
              transform: translateX(10%);
            }
          }

          &--disabled {
            opacity: 0.3;
            cursor: default;
          }
        }
      

        &__cards {
          display: flex;
          align-items: stretch;
          width: 70vw;
          @media screen and (min-width: $tablet-breakpoint) {
            width: 75vw;
          }
          @media screen and (min-width: $desktop-breakpoint) {
            width: 85vw;
            max-width: 1260px;
          }
          &__card {
            flex-grow: 1;
            flex-shrink: 0;
            display: flex;
            align-items: stretch;
            padding: 40px 10px 0 10px;
            @media screen and (min-width: $tablet-breakpoint) {
              padding: 56px 15px 0 15px;

            }
            @media screen and (min-width: $desktop-breakpoint) {
              padding: 77px 20px 0 20px;

            }
            &__inner {
              border: 1px solid #01498B;
              border-radius: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;

              @media screen and (min-width: $desktop-breakpoint) {
                border: 2px solid #01498B;
              }

              &__picture {
                margin-top: -45px;
              
                img {
                  filter: grayscale(0.7);
                  width: 90px;
                  height: 90px;
                  border-radius: 45px;
                }

                @media screen and (min-width: $desktop-breakpoint) {
                  margin-top: -52px;
                  img {
                    width: 104px;
                    height: 104px;
                    border-radius: 52px;
                  }
                }
              }

              &__name {
                margin: 10px 15px 0;
                color: #01498B;
                font-size: 22px;
                font-weight: 700;
                @media screen and (min-width: $tablet-breakpoint) {
                  font-size: 24px;
                }

                @media screen and (min-width: $desktop-breakpoint) {
                  font-size: 26px;
                }
              }

              &__title {
                margin: 5px 15px 0;
                font-size: 18px;
                font-weight: 200;
                @media screen and (min-width: $desktop-breakpoint) {
                  font-weight: 300;
                }
              }

              &__stars {
                margin-top: 15px;
                &__star {
                  width: 24px;
                  height: 24px;
                }
              }

              &__quote {
                margin-top: 13px;
                img {
                  width: 28px;
                  height: 22px;
                }
              }

              &__comment {
                text-align: center;
                margin: 15px;
                font-size: 18px;
                font-weight: 200;
                flex-grow: 1;
                @media screen and (min-width: $desktop-breakpoint) {
                  font-weight: 300;
                }
              }
            }
          }
        }

        &__puces {
          margin-top: 25px;
          display: flex;
          justify-content: center;
          align-items: stretch;

          &__puce {
            margin: 0 2px;
          }
        }
      }
    }
  }
</style>