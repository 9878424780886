<template>
  <!-- <SitePreHeader /> -->
  <SiteHeader
    @login="showLogin"
  />
  <SiteHeroBanner
  />
  <main>
    <SitePitch
      id="pitch"
      ref="pitch"
      data-hash
      @signup="showSignUp('pitch')"
    />
    <SiteFeatures
      id="features"
      ref="features"
      data-hash
    />
    <SitePrice
      id="price"
      ref="price"
      data-hash
      @signup="showSignUp('price-' + $event)"
    />
    <SiteConversion
      @signup="showSignUp('conversion')"
    />
    <SiteSocial
      id="feedback"
      ref="feedback"
      data-hash
    />
  </main>
  <SiteFooter
  />
  <SitePostFooter />

  <SiteSignUp
    allow-desauthent
    v-model="signUpVisible"
    @login="showLogin"
    @authenticated="goToApp"
  />
  <SiteLogin
    allow-desauthent
    v-model="loginVisible"
    @signup="showSignUp('login')"
    @authenticated="goToApp"
  />
  <GlobalEvents
    target="window"
    @scroll="checkHashTag"
  />

  <ClientOnly>
    <BrevoConversation />
  </ClientOnly>
</template>

<script setup>
  import SitePreHeader from '@/sections/SitePreHeader'
  import SiteHeader from '@/sections/SiteHeader'
  import SiteHeroBanner from '@/sections/home/SiteHeroBanner'
  import SitePitch from '@/sections/home/SitePitch'
  import SitePrice from '@/sections/home/SitePrice'
  import SiteConversion from '@/sections/home/SiteConversion'
  import SiteSocial from '@/sections/home/SiteSocial'
  import SiteFeatures from '@/sections/home/SiteFeatures'
  import SiteFooter from '@/sections/SiteFooter'
  import SitePostFooter from '@/sections/SitePostFooter'
  import SiteSignUp from '@/components/SiteSignUp'
  import SiteLogin from '@/components/SiteLogin'
  import BrevoConversation from '@/components/BrevoConversation'
  import { GlobalEvents } from 'vue-global-events'
  import debounce from 'lodash.debounce'
  import useIdentity from '@/components/SiteIdentity'

  const siteIdentity = useIdentity()
  const route = useRoute()

  await siteIdentity.synchronizeAuthAndIdent(true, false)

  definePageMeta({
    name: 'home'
  })

  useSeoMeta({
    title: 'Freelance Régie Tools | Gestion Simplifiée des CRA et Factures pour Freelances IT',
    ogSiteName: 'Freelance Régie Tools',
    ogTitle: 'Freelance Régie Tools | Gestion Simplifiée des CRA et Factures pour Freelances IT',
    description: 'Génération gratuite et en ligne des Comptes Rendus d\'Activité (CRA) et des factures en deux clics pour les Freelances en régie!',
    ogDescription: 'Génération gratuite et en ligne des Comptes Rendus d\'Activité (CRA) et des factures en deux clics pour les Freelances en régie!',
    ogImage: '/android-chrome-512x512.png'
  })

  const signUpVisible = ref(false)

  const showSignUp = (from) => {
    signUpVisible.value = from || true
  }

  const loginVisible = ref(false)

  const showLogin = ()=> {
    loginVisible.value = true
  }

  const checkHashTag = debounce(() => {
    const hashTagSections = Array.from(document.querySelectorAll('[data-hash]'))

    const tops = hashTagSections.filter((target) => {
      if (target) {
        const top = target.getBoundingClientRect().y
        const height = target.clientHeight
        const topIsPassed = top < 100
        const bottomIsNotPassed = top + height > 100
        return topIsPassed && bottomIsNotPassed
      } else {
        return false
      }
     
    })
    const [firstVisible] = tops

    if (firstVisible) {
      const newHash = `#${firstVisible.id}`
      if (location.hash !== newHash) {
        history.replaceState(null, null, document.location.pathname + newHash);
      }
    } else {
      history.replaceState(null, null, document.location.pathname);
    }
  }, 200, {
    maxWait: 200
  })

  onMounted(() => {
    const hash = route.hash
    if (hash) {
      setTimeout(() => {
        const top = document.getElementById(hash.replace('#', '')).getBoundingClientRect().y
        window.scrollTo({ top: top + window.scrollY, behavior: 'smooth' })
      }, 300)
    }
  })

  const goToApp = () => {
    loginVisible.value = false
    signUpVisible.value = false
    const config = useRuntimeConfig()
    location.href = config.public.freelanceAppUrl
  }

</script>

<style>
html {
  font-family: 'Outfit';
  font-size: 18px;
  font-weight: 300;
}
</style>